import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "asFileUploader",
  ref: "asFileUploader"
}
const _hoisted_2 = { class: "asFileUploader_start" }
const _hoisted_3 = { class: "asFileUploader_button" }
const _hoisted_4 = ["accept"]
const _hoisted_5 = { class: "asFileUploader_container" }
const _hoisted_6 = { class: "asFileDnD_text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_upload = _resolveComponent("icon-upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("label", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_component_icon_upload),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('UploadFile')), 1)
      ]),
      _createElementVNode("input", {
        accept: _ctx.accept,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fileSelect && _ctx.fileSelect(...args))),
        class: "asFileUploader_input",
        ref: "fileEl",
        type: "file",
        "data-cy": "asFileDnD_input"
      }, null, 40, _hoisted_4)
    ], 512), [
      [_vShow, _ctx.status === 'start']
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('uploadFile')) + " (" + _toDisplayString(_ctx.progress) + "%)", 1)
    ], 512), [
      [_vShow, _ctx.status === 'sending']
    ])
  ], 512))
}

import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from 'vue'

import 'video.js/dist/video-js.css'
import 'videojs-record/dist/css/videojs.record.css'
import 'webrtc-adapter'
import 'videojs-record/dist/videojs.record.js'
import 'recordrtc'
import 'videojs-record/dist/plugins/videojs.record.ffmpegjs.js'

import videojs, { VideoJsPlayer } from 'video.js'
import uuid from 'uuid/v4'

import sentry from '@web/common/sentry'

export default defineComponent({
  props: {
    type: {
      type: String,
      default: 'video'
    }
  },
  emits: ['recordedData'],
  setup (props, ctx) {
    const id = ref(`videoPlayer-${uuid()}`)
    const player = ref<VideoJsPlayer | null>(null)
    const options = ref({
      controls: true,
      autoplay: true,
      width: 640,
      height: 480,
      fluid: true,
      loop: false,
      controlBar: {
        volumePanel: false
      },
      plugins: {
        record: {
          video: true,
          image: false,
          maxLength: 100
        }
      }
    })

    const recordOptions = computed(() => {
      const _options = {
        video: {
          video: true,
          maxLength: 100
        },
        image: {
          image: true
        }
      }
      return _options[props.type]
    })

    function initPlayer () {
      options.value = {
        ...options.value,
        ...{
          plugins: {
            record: recordOptions.value
          }
        }
      }
      player.value = videojs(`#${id.value}`, options.value, () => ({}))

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      player.value.record().getDevice()

      player.value.on('finishRecord', () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { recordedData } = player.value
        ctx.emit('recordedData', recordedData)
      })

      player.value.on('retry', () => {
        ctx.emit('recordedData', '')
      })

      player.value.on('error', (element, error) => {
        sentry.captureException(error)
      })

      player.value.on('deviceError', () => {
        sentry.captureException(player.value?.error())
      })
    }

    onMounted(() => {
      initPlayer()
    })

    onBeforeUnmount(() => {
      if (player.value) {
        player.value.dispose()
      }
    })
    return {
      id
    }
  }
})

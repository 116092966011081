import { computed, Ref, ref } from 'vue'
import HOMEWORK_UPLOAD_FILE_TYPE from '@web/consts/HomeworkUploadFileType'
import HOMEWORK_COMMENT_TYPE from '@web/consts/HomeworkCommentType'
import Swal from 'sweetalert2'
import HomeworkStatus from '@web/consts/HomeworkStatus'
import Analytics from '@web/services/Analytics/Analytics'
import * as AnalyticEvents from '@web/services/Analytics/events'
import sentry from '@web/common/sentry'
import { getFileUploadErrorMessage } from '@web/common/Utils'
import { useStore } from '@web/store'
import Logger from '@web/common/Logger'
import { IS_WEB } from '@web/common/config'
import { Router, RouteLocationNormalized } from 'vue-router'
import { passHomeworkSupportCategoryId } from '@web/common/ExternalConfig/getters'
import i18n from '@web/plugins/i18n'

async function sendHomework ({
  files,
  message,
  comment,
  chatId,
  streamId,
  homeworkStatus,
  requireExpertApprove
}: {
  files: number[];
  message: string;
  comment: string;
  chatId: number;
  streamId: number;
  homeworkStatus: HomeworkStatus;
  requireExpertApprove: boolean;
}) {
  const store = useStore()
  try {
    await store.dispatch('chat/sendHomeworkMessage', {
      id: chatId,
      streamId,
      userHomeworkStatusId: homeworkStatus.id,
      message: {
        message: message,
        comment,
        userId: store.getters['user/user'].id,
        dialogId: chatId,
        files
      }
    })

    store.commit('homework/setHomeworkStatus', {
      id: homeworkStatus.id,
      chatId,
      streamId,
      targetId: homeworkStatus.targetId,
      status: requireExpertApprove ? HomeworkStatus.UPLOADED : HomeworkStatus.DONE
    })

    store.dispatch('userScore/amount')

    AnalyticEvents.taskSend({
      homeworkId: homeworkStatus.id.toString(),
      homeworkType: homeworkStatus.targetType,
      ...(homeworkStatus.targetType === 'course_stage_homework' && { homeworkStageId: homeworkStatus.targetId.toString() }),
      ...(homeworkStatus.targetType === 'stage_day_homework' && { homeworkDayId: homeworkStatus.targetId.toString() })
    })

    Swal.fire({
      icon: 'success',
      title: i18n.global.t('sentHomework'),
      timer: 3000,
      showConfirmButton: false
    })
  } catch (e) {
    const alert = (message?: string) => Swal.fire({
      title: i18n.global.t('Error'),
      text: `${i18n.global.t('error.sendHomework')}. ${message || ''}`,
      icon: 'error'
    })
    getFileUploadErrorMessage(e)
      .then(alert)
      .catch(() => alert())

    throw e
  }
}

type params = {
  homework: {
    status: HomeworkStatus;
    homework: Homework;
    stageHomework: StageHomework | undefined;
    dayHomework: DayHomework | undefined;
  };
  elMessage: Ref<Element | null>;
  chatId: number;
  route: RouteLocationNormalized;
  router: Router;
  reset: () => void;
}
export default function useFormHomework ({
  homework,
  elMessage,
  chatId,
  route,
  router,
  reset
}: params) {
  const store = useStore()
  const files = ref<Dictionary<File>>({})
  const message = ref('')
  const fileStatus = ref('not_loaded')
  const selectedExercise = ref('')
  const homeworkSending = ref<boolean>(false)

  const uploadFileType = computed<homeworkUploadFileType>(() => homework?.stageHomework?.uploadFileType || homework?.dayHomework?.uploadFileType || HOMEWORK_UPLOAD_FILE_TYPE.UPLOAD_FILE_REQUIRED)
  const isUploadFileNone = computed<boolean>(() => uploadFileType.value === HOMEWORK_UPLOAD_FILE_TYPE.UPLOAD_FILE_NONE)
  const isUploadFileRequired = computed<boolean>(() => uploadFileType.value === HOMEWORK_UPLOAD_FILE_TYPE.UPLOAD_FILE_REQUIRED)

  const submitButtonText = computed<string>(() => {
    if (IS_WEB) {
      return homeworkSending.value ? i18n.global.t('sending') : i18n.global.t('sendHomework')
    } else {
      return homeworkSending.value ? i18n.global.t('sending') : i18n.global.t('send')
    }
  })

  const commentRequired = computed<homeworkCommentRequired>(() => homework?.dayHomework?.commentRequired || homework?.stageHomework?.commentRequired || HOMEWORK_COMMENT_TYPE.COMMENT_REQUIRED)
  const isCommentDisabled = computed<boolean>(() => commentRequired.value === HOMEWORK_COMMENT_TYPE.COMMENT_DISABLED)
  const isCommentRequired = computed<boolean>(() => commentRequired.value === HOMEWORK_COMMENT_TYPE.COMMENT_REQUIRED)
  const exerciseList = computed(() => homework?.homework?.answers?.map(a => {
    return {
      value: a,
      text: a
    }
  }) || [])
  const isShowExercise = computed<boolean>(() => Boolean(exerciseList.value.length))
  const isShowMessageBox = computed<boolean>(() => !(isShowExercise.value || isCommentDisabled.value))

  const canSend = computed<boolean>(() => {
    if (isShowExercise.value && !selectedExercise.value) {
      return false
    }

    if (isUploadFileRequired.value && !Object.keys(files.value).length) {
      return false
    }

    return Boolean(message.value) || Boolean(Object.keys(files.value).length) || Boolean(selectedExercise.value)
  })

  const isSubmitBtnDisabled = computed(() => !canSend.value || homeworkSending.value)
  const isShowSubmitBtn = computed(() => !isCommentDisabled.value || !isUploadFileNone.value || isShowExercise.value)

  function onLoadingFile (status) {
    fileStatus.value = status
  }
  function addFile (file) {
    files.value = {
      ...files.value,
      [file.id]: file
    }

    fileStatus.value = 'loaded'

    reset()
  }
  function removeFile ({ id }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const remove = ({ [id]: _, ...rest }) => rest
    files.value = remove(files.value)
  }
  function input (e) {
    message.value = e.target.innerText
  }
  async function submit () {
    if (homeworkSending.value) {
      return
    }

    if (isCommentRequired.value && !message.value && !isShowExercise.value) {
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('Error'),
        text: i18n.global.t('error.requireMessageHomework')
      })
      return
    }

    if ((isUploadFileRequired.value) && !Object.keys(files.value).length) {
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('Error'),
        text: i18n.global.t('error.requireFileHomework')
      })
      return
    }

    if (isShowExercise.value && !selectedExercise.value) {
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('Error'),
        text: i18n.global.t('error.requireExerciseHomework')
      })
      return
    }

    if (!canSend.value) {
      return
    }

    if (fileStatus.value === 'sending') {
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('Error'),
        text: i18n.global.t('uploadingFilePleaseWait')
      })
      return
    }

    if (fileStatus.value === 'fail') {
      const resultModal = await Swal.fire({
        icon: 'warning',
        text: `${i18n.global.t('sendHomeworkWithoutFile')}`,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: i18n.global.t('Cancel')
      })
      if (!resultModal.value) {
        return
      }
    }

    homeworkSending.value = true

    try {
      await sendHomework({
        streamId: homework.status.streamId,
        files: Object.keys(files.value).map(Number),
        message: message.value,
        comment: selectedExercise.value,
        chatId,
        homeworkStatus: homework.status,
        requireExpertApprove: Boolean(homework?.dayHomework?.requireExpertApprove || homework?.stageHomework?.requireExpertApprove)
      })

      files.value = {}
      fileStatus.value = 'not_loaded'
      message.value = ''
    } catch (e) {
      sentry.captureException(e)
      Logger.log(e)
    } finally {
      homeworkSending.value = false
    }

    if (elMessage.value) {
      elMessage.value.innerHTML = ''
    }
  }
  function triggerFocus (e) {
    e.target.focus()
  }

  function focus () {
    setTimeout(() => {
      if (elMessage.value) {
        elMessage.value.scrollIntoView(true)
      }
    }, 1000)
  }

  function onChangeExercise (ex) {
    selectedExercise.value = ex
  }

  function goToExpertChat (chatId) {
    store.commit('router/setReturnUrl', route.fullPath)
    router.push({
      name: 'chat',
      params: {
        chatId: String(chatId)
      }
    })
  }

  function isShowSupportBtn (streamId: number): boolean {
    const stream = store.getters['stream/getStreamById'](streamId)
    if (!stream) {
      return false
    }
    return !stream.courseFullAccess && !!passHomeworkSupportCategoryId()
  }

  function goToSupport (streamId: number) {
    router.push({
      name: 'support-category-stream',
      params: {
        categoryId: String(passHomeworkSupportCategoryId()),
        streamId: String(streamId)
      }
    })

    Analytics.send({
      action: 'support_cancel_task'
    })
  }

  return {
    message,
    files,
    isUploadFileNone,
    isSubmitBtnDisabled,
    isShowSubmitBtn,
    submitButtonText,
    isShowMessageBox,
    isShowExercise,
    selectedExercise,
    exerciseList,
    input,
    triggerFocus,
    submit,
    onLoadingFile,
    addFile,
    removeFile,
    onChangeExercise,
    focus,
    homeworkSending,
    goToExpertChat,
    isShowSupportBtn,
    goToSupport
  }
}

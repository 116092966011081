
import { defineComponent, ref, onMounted } from 'vue'

import IconUpload from './icons/IconUpload.vue'

import FileApi from '@web/api/modules/File'
import i18n from '@web/plugins/i18n'
import sentry from '@web/common/sentry'
import Swal from 'sweetalert2'
import { getFileUploadErrorMessage } from '@web/common/Utils'
import Logger from '@web/common/Logger'

export default defineComponent({
  name: 'AsFileUploader',
  components: {
    IconUpload
  },
  props: {
    accept: {
      type: String,
      default: () => 'video/*,image/*'
    }
  },
  filters: {
    fileName: label => {
      return label.length > 15 ? label.substr(0, 15) + '...' : label
    }
  },
  emits: ['loadingFile', 'load'],
  setup (props, ctx) {
    const status = ref('start')
    const progress = ref(0)
    const fileEl = ref<HTMLInputElement | null>(null)
    const asFileUploader = ref<HTMLElement | null>(null)

    function reset () {
      status.value = 'start'
      if (fileEl.value) {
        fileEl.value.value = ''
      }
    }
    function fileUpload (fileData) {
      status.value = 'sending'
      ctx.emit('loadingFile', 'sending')
      FileApi.upload(fileData, {
        onUploadProgress: progressEvent => {
          // не показываю 100%, т.к. сервер ещё какое-то время обрабатывает файл
          progress.value = Math.floor((progressEvent.loaded * 95) / progressEvent.total)
        }
      })
        .then(({ data }) => {
          ctx.emit('load', data)
        })
        .catch((error) => {
          status.value = 'start'
          sentry.captureException(error)

          ctx.emit('loadingFile', 'fail')
          const alert = (message?: string) => {
            const text = `${i18n.global.t('error.uploadFile')}. ${message || ''}`
            Swal.fire({
              icon: 'error',
              title: i18n.global.t('Error'),
              text
            })
          }
          getFileUploadErrorMessage(error)
            .then(alert)
            .catch(() => alert())
          Logger.error(error)
        })
        .finally(() => {
          reset()
        })
    }
    function fileSelect (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files[0]) return

      fileUpload(files[0])
      e.target.value = ''
    }

    onMounted(() => {
      asFileUploader.value?.addEventListener('reset', reset)
    })

    return {
      status,
      progress,

      fileSelect,
      fileEl,
      asFileUploader
    }
  }
})

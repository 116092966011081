import Swal from 'sweetalert2'
import HomeworkStatus from '@web/consts/HomeworkStatus'
import { getUrlWithAuthParams, isOffline, performanceEnd } from '@web/common/Utils'
import { ref, computed, onBeforeUnmount } from 'vue'
import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'
import Analytics from '@web/services/Analytics/Analytics'
import HOMEWORK_UPLOAD_FILE_TYPE from '@web/consts/HomeworkUploadFileType'
import HOMEWORK_COMMENT_TYPE from '@web/consts/HomeworkCommentType'
import { useStore } from '@web/store'
import { IS_MOBILE } from '@web/common/config'
import { getUrl } from '@web/api/utils'
import { Router, RouteLocationNormalizedLoaded } from 'vue-router'
import STREAM_STATUS from '@web/consts/StreamStatus'
import i18n from '@web/plugins/i18n'
import { User } from '@web/store/types/modules/user'
import { Menu } from '@web/store/types/modules/menu'
import { DayStatus, Stream } from '@web/store/types/modules/stream'

function useHomework ({ route, router }: { route: RouteLocationNormalizedLoaded; router: Router }) {
  const store = useStore()
  const loaded = ref<boolean>(false)
  const loadedChat = ref<boolean>(true)
  const homeworkSending = ref<boolean>(false)
  const showChatForm = ref<boolean>(true)

  const user = computed<User | undefined>(() => store.getters['user/user'])
  const menu = computed<Menu>(() => store.getters['menu/general'])
  const homeworkId = computed<number>(() => parseInt(route.params.homeworkId?.toString(), 10))
  // TODO: инциализировать после загрузки данных
  // PD-1579
  const homework = computed<{
    status: HomeworkStatus | undefined;
    homework: Homework | undefined;
    stageHomework: StageHomework | undefined;
    dayHomework: DayHomework | undefined;
  }>(() => store.getters['homework/getHomeworkByStatusId'](homeworkId.value) || {})
  const stream = computed<Stream | undefined>(() => homework.value?.status?.streamId ? store.getters['stream/getStreamById'](homework.value?.status?.streamId) : undefined)
  const streamEnd = computed<boolean>(() => stream.value?.status === STREAM_STATUS.ENDED || stream.value?.status === STREAM_STATUS.STOPPED)
  const streamId = Number(route.params?.streamId)
  const course = computed<Course | undefined>(() => stream.value?.courseId ? store.getters['course/getCourseById'](stream.value?.courseId) : undefined)
  const day = computed(() => store.getters['day/getDayByHomeworkId'](homework.value.status?.targetId || 0))
  const dayStatus = computed<DayStatus | undefined>(() => store.getters['stream/getDayStatus']({ streamId: streamId || 0, dayId: day.value?.id || 0 }))
  const title = i18n.global.t('homework')

  const chatTitle = computed<string>(() => homework.value?.homework?.title || '')
  const status = computed<string>(() => homework.value?.status?.status || '')
  const paidConsultationsOver = computed<boolean>(() => {
    if (!stream.value) {
      return true
    }
    const { maxConsultationCount } = stream.value
    const consultationCount = stream.value?.chatChannelId ? store.getters['consultation/getConsultationsCountByChatId'](stream.value?.chatChannelId) : 0
    const n = maxConsultationCount - consultationCount
    return n <= 0
  })
  const isFree = computed<boolean>(() => {
    return !!homework.value?.dayHomework?.isFree
  })

  const canSendHomework = computed<boolean>(() => {
    if (!isFree.value && course.value?.consultationLimit && paidConsultationsOver.value) {
      return false
    }

    if (IS_MOBILE && isOffline()) {
      return false
    }

    const status = homework.value?.status?.status
    return status === HomeworkStatus.NOT_UPLOADED || status === HomeworkStatus.FAIL
  })
  const isShowSurveyFormBtn = computed<boolean>(() => Boolean(homework.value?.homework?.surveyFormId) && !streamEnd.value)
  const surveyFormUrl = computed<string>(
    () => {
      const url = `/survey-form/redirect-url?target_id=${homeworkId.value}&target_type=user_homework_status`
      return getUrlWithAuthParams(getUrl(url))
    }
  )
  const isShowFormHomework = computed<boolean>(() => canSendHomework.value)

  const uploadFileType = computed<string | undefined>(() => homework.value?.stageHomework?.uploadFileType || homework.value?.dayHomework?.uploadFileType)
  const isUploadFileNone = computed<boolean>(() => uploadFileType.value === HOMEWORK_UPLOAD_FILE_TYPE.UPLOAD_FILE_NONE)

  const commentRequired = computed<string | undefined>(() => homework.value?.dayHomework?.commentRequired || homework.value?.stageHomework?.commentRequired)
  const isCommentDisabled = computed<boolean>(() => commentRequired.value === HOMEWORK_COMMENT_TYPE.COMMENT_DISABLED)
  const isShowExercise = computed<boolean>(() => Boolean(homework.value?.homework?.answers?.length))
  // не показывать форму и статус если пользователь не может отправить сообщение, отправить файл или выбрать один из вариантов ответа
  const isShowFormHomeworkAndStatus = computed<boolean>(() => loaded.value && !(isCommentDisabled.value && isUploadFileNone.value && !isShowExercise.value) && !streamEnd.value)
  const isShowFormHomeworkAndStatusWithSurveyForm = computed<boolean>(() => loaded.value && (!(isCommentDisabled.value && isUploadFileNone.value && !isShowExercise.value) || isShowSurveyFormBtn.value) && !streamEnd.value)

  function checkHomeworkId () {
    if (!homework?.value?.status?.id) {
      if (route.name === 'homework') {
        router.push({ name: 'homeworks' })
      }
      if (route.name === 'task') {
        router.push({ name: 'tasks' })
      }
    }
  }

  function showErrorAlert () {
    if (route.name === 'homework') {
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('error.error'),
        text: i18n.global.t('error.homework')
      })
    }
  }

  async function fetchData () {
    const stream = await store.dispatch('stream/fetchUserCourseStreamCached', { id: streamId })
    const fetchPromises: unknown[] = []

    fetchPromises.push(store.dispatch('stage/fetchStagesCached', { courseId: stream.courseId }))
    fetchPromises.push(store.dispatch('stream/fetchStatusesCached', {
      id: streamId,
      expand: 'days,homeworks,stages,consultations'
    }))

    await Promise.all(fetchPromises)
  }

  function setPageData () {
    if (homework.value && homework.value.status && homework.value.homework && course.value && stream.value) {
      Analytics.setPageData({
        courseTitle: course.value.title,
        courseId: course.value.id.toString(),
        streamId: stream.value.streamId.toString(),
        startDate: stream.value.startDate.toString(),
        endDate: stream.value.endDate.toString(),
        userCourseStreamId: stream.value.id.toString(),
        dialogId: stream.value.chatChannelId.toString(),
        homeworkStatusId: homework.value.status.id.toString(),
        homeworkId: homework.value.homework.id.toString(),
        homeworkTitle: homework.value.homework.title,
        homeworkStatusTargetType: homework.value.status.targetType,
        homeworkStatusTargetId: homework.value.status.targetId.toString()
      })
    }
  }

  async function init () {
    let homeworkLoading: number = performance.now()
    try {
      await fetchData()
      setPageData()
    } catch (e) {
      if (['homework', 'task'].includes(router.currentRoute.value.name?.toString() || '')) {
        sentry.captureException(e)
        showErrorAlert()
      }
      Logger.error(e)
    } finally {
      homeworkLoading = performanceEnd(homeworkLoading)
      Logger.info(`Homework loaded: ${homeworkLoading} ms`)
      loaded.value = true
      checkHomeworkId()
    }
  }

  onBeforeUnmount(() => {
    Analytics.clearPageData()
  })

  return {
    loaded,
    loadedChat,
    homeworkSending,
    showChatForm,

    user,
    menu,
    stream,
    streamId,
    course,
    title,
    chatTitle,
    homeworkId,
    homework,
    day,
    dayStatus,
    paidConsultationsOver,
    canSendHomework,
    status,
    isFree,
    isShowSurveyFormBtn,
    surveyFormUrl,
    isShowFormHomework,
    isShowFormHomeworkAndStatus,
    isShowFormHomeworkAndStatusWithSurveyForm,

    init,
    showErrorAlert,
    fetchData,
    checkHomeworkId,
    setPageData,
    streamEnd
  }
}

export default useHomework

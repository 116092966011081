
import { defineComponent, ref, computed, nextTick } from 'vue'

import i18n from '@web/plugins/i18n'

interface Item {
  value: string;
  text: string;
}

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: i18n.global.t('Choose')
    },
    items: {
      type: Array,
      required: true
    },
    modelValue: {
      type: [Number, String],
      required: true
    },
    selected: {
      type: Object,
      default: null
    },
    accordion: {
      type: Boolean,
      default: false
    },
    positionMenu: {
      type: String,
      default: null
    }
  },
  emits: ['toggle', 'update:modelValue'],
  setup (props, ctx) {
    const isShowMenu = ref(false)
    const selectedItem = computed(() => props.selected || props.items.find(i => (i as Item).value === props.modelValue))
    const positionMenuClass = computed(() => {
      if (props.accordion) {
        return '-bottomMenu'
      }
      if (props.positionMenu) {
        return `-${props.positionMenu}Menu`
      }

      return '-bottomMenu'
    })
    const additionalClasses = computed(() => {
      const classes: string[] = []
      classes.push(positionMenuClass.value)
      if (props.accordion) {
        classes.push('-accordion')
      }
      if (isShowMenu.value) {
        classes.push('-menuVisible')
      }
      return classes.join(' ')
    })

    function onShowMenu () {
      isShowMenu.value = !isShowMenu.value
      nextTick(() => {
        ctx.emit('toggle')
      })
    }

    function onCloseMenu () {
      isShowMenu.value = false
      nextTick(() => {
        ctx.emit('toggle')
      })
    }

    function onSelectItem (item) {
      onCloseMenu()
      ctx.emit('update:modelValue', item)
    }

    return {
      isShowMenu,
      selectedItem,
      positionMenuClass,
      additionalClasses,
      onShowMenu,
      onCloseMenu,
      onSelectItem
    }
  }
})

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-analytics-element-name"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.url)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "asButtonTypeForm",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args))),
        "data-analytics": "",
        "data-analytics-element-type": "other",
        "data-analytics-element-id": "typeform",
        "data-analytics-element-name": `${_ctx.$t('tookSurveyHomework')} ${_ctx.homeworkId}`
      }, [
        (_ctx.result)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('yourResult')) + " " + _toDisplayString(_ctx.result.score) + "/" + _toDisplayString(_ctx.result.maxScore) + ". " + _toDisplayString(_ctx.$t('tryPassTestAgain')), 1)
            ], 64))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('passTest')), 1)
            ], 64))
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}
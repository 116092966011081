
import { defineComponent, ref, onMounted, computed } from 'vue'
import { MqResponsive, useMq } from 'vue3-mq'

import AsFileDragAndDrop from '@web/components/AsFileDnD.vue'
import AsFileUploader from '@web/components/AsFileUploader.vue'
import AsCamera from '@web/components/AsCamera.vue'
import AsDropDown from '@web/components/AsDropDown.vue'
import useFormHomework from '@web/composition/useFormHomework'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'AsFormHomework',
  components: {
    MqResponsive,
    AsFileDragAndDrop,
    AsFileUploader,
    AsCamera,
    AsDropDown
  },
  props: {
    expertsChatId: {
      type: Number,
      required: true
    },
    homework: {
      type: Object as () => {
        status: HomeworkStatus;
        homework: Homework;
        stageHomework: StageHomework | undefined;
        dayHomework: DayHomework | undefined;
      },
      required: true
    }
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const asFormHomeworkEl = ref<HTMLElement | null>()
    const elMessage = ref<Element | null>(null)
    const elFileUpload = ref<typeof AsFileDragAndDrop | null>(null)
    const refTextareaWrap = ref<HTMLDivElement | null>(null)
    const refTextarea = ref<HTMLTextAreaElement | null>(null)

    const { message, ...data } = useFormHomework({
      homework: props.homework,
      chatId: props.expertsChatId,
      elMessage,
      router,
      route,
      reset () {
        if (elFileUpload.value) {
          elFileUpload.value.$el.dispatchEvent(new CustomEvent('reset'))
        }
      }
    })

    function autoResize () {
      const _autoResize = false // props.autoResize
      if (_autoResize && refTextarea.value && refTextareaWrap.value) {
        const styles = window.getComputedStyle(refTextarea.value, null)
        const _minHeight = parseFloat(styles.getPropertyValue('min-height'))
        let _maxHeight = parseFloat(styles.getPropertyValue('max-height'))
        const _lineHeight = parseFloat(styles.getPropertyValue('line-height'))

        if (!_maxHeight) {
          const _maxRows = 4 // props.maxRows
          _maxHeight = _maxRows * _lineHeight + 1
          refTextarea.value.style.maxHeight = `${_maxHeight}px`
        }

        let _height = refTextarea.value.scrollHeight || (refTextarea.value.value.split(/[\n\r]/).length * _lineHeight + refTextarea.value.value.split(/[\n\r]/).length)
        _height = Math.max(_minHeight, _height)
        _height = Math.min(_maxHeight, _height)
        refTextareaWrap.value.style.height = `${_height}px`
      }
    }

    function setMaxHeight () {
      const _autoResize = false // props.autoResize
      const _maxRows = 4 // props.maxRows
      const _fullHeight = false // props.fullHeight
      if (refTextarea.value) {
        const styles = window.getComputedStyle(refTextarea.value, null)
        const _lineHeight = parseFloat(styles.getPropertyValue('line-height'))

        if (_autoResize) {
          const _maxHeight = _maxRows * _lineHeight + 1
          refTextarea.value.style.maxHeight = `${_maxHeight}px`
        }

        if (_fullHeight) {
          refTextarea.value.style.height = `${_maxRows * _lineHeight}px`
        }
      }
    }

    const messageModel = computed({
      get () {
        return message.value
      },
      set (newMessage: string) {
        message.value = newMessage // .slice(0, messageMaxLength)
        autoResize()
      }
    })

    function handlerFocusTextarea (event) {
      setTimeout(() => {
        event.target.focus()
        event.target.scrollIntoViewIfNeeded()
      }, 300)
    }

    function msg (str: string) {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    const mq = useMq()

    let status: 'open' | 'close' = 'close'
    function setDropDownMenuHeightForMobile () {
      if (window.innerWidth < 1244) {
        const menuTarget: HTMLElement | null = document.querySelector('.asDropDown_menu')
        if (menuTarget) {
          menuTarget.style.maxHeight = 'calc(100vh - 378px)'
        }
      }
    }
    function setHeightForMobile () {
      if (!asFormHomeworkEl.value) {
        return
      }

      if (status === 'close') {
        asFormHomeworkEl.value.style.height = '76px'
        asFormHomeworkEl.value.style.minHeight = ''
        asFormHomeworkEl.value.style.overflow = 'hidden'
      } else {
        const hPreviews = (document.querySelector('.asFormHomework_previews') as HTMLElement).scrollHeight
        const hContent = (document.querySelector('.asFormHomework_content') as HTMLElement).scrollHeight

        asFormHomeworkEl.value.style.height = ''
        asFormHomeworkEl.value.style.minHeight = `${95 + hPreviews + hContent}px`
        asFormHomeworkEl.value.style.overflow = 'auto'
      }
    }
    function toggleVisibility () {
      status = status === 'open' ? 'close' : 'open'
      setHeightForMobile()
      setDropDownMenuHeightForMobile()
    }

    onMounted(() => {
      setDropDownMenuHeightForMobile()
      setMaxHeight()
      autoResize()
    })

    return {
      ...data,
      message,
      messageModel,
      handlerFocusTextarea,
      msg,
      setHeightForMobile,
      toggleVisibility,
      status,
      mq,
      asFormHomeworkEl
    }
  }
})


import { ref, defineComponent, onMounted, watch } from 'vue'
import { MqResponsive } from 'vue3-mq'
import Swal from 'sweetalert2'

// Components
import AsChat from '@web/components/AsChat.vue'
import AsRow from '@web/components/AsRow.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsTitle from '@web/components/AsTitle.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsPreloader from '@web/components/AsPreloader.vue'
import AsFormHomework from '@web/components/AsFormHomework.vue'
import AsButtonTypeForm from '@web/components/AsButtonTypeForm.vue'
import AsRenewConsultations from '@web/components/AsRenewConsultations.vue'
import AsHomeworkStatus from '@web/components/AsHomeworkStatus.vue'

import useHomework from '@web/composition/useHomework'
import AsRenewCourse from '@web/components/AsRenewCourse.vue'
import FirebasePerformance from '@web/services/firebasePerformance'
import { useRouter, useRoute } from 'vue-router'
import { useMeta } from '@web/common/vueMeta'
import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'

import DAY_STATUS from '@web/consts/DayStatus'
import i18n from '@web/plugins/i18n'

export default defineComponent({
  name: 'Homework',
  components: {
    MqResponsive,
    AsHeaderMobile,
    AsRenewCourse,
    AsRow,
    AsChat,
    AsMenu,
    AsHeaderMain,
    AsSpacer,
    AsFooter,
    AsTitle,
    AsBreadcrumb,
    AsPreloader,
    AsFormHomework,
    AsButtonTypeForm,
    AsRenewConsultations,
    AsHomeworkStatus
  },
  setup () {
    const router = useRouter()
    const route = useRoute()

    const isOpenMenu = ref(false)

    const {
      chatTitle,
      loaded,
      stream,
      streamId,
      homework,
      homeworkId,
      canSendHomework,
      menu,
      title,
      status,
      isFree,
      isShowSurveyFormBtn,
      surveyFormUrl,
      isShowFormHomework,
      isShowFormHomeworkAndStatus,
      course,
      init,
      dayStatus
    } = useHomework({ route, router })

    useMeta({
      title
    })

    onMounted(async () => {
      const startLoading = Date.now()
      try {
        await init()
      } finally {
        FirebasePerformance.record({
          traceName: 'load_homework',
          startTime: startLoading,
          options: {
            attributes: {
              userCourseStreamId: streamId.toString(),
              streamId: (stream.value?.streamId || '').toString(),
              courseId: (course.value?.id || '').toString(),
              courseTitle: course.value?.title || '',
              dialogId: stream.value?.chatChannelId.toString() || '',
              homeworkStatusId: homework.value.status?.id.toString() || '',
              homeworkId: homework.value.homework?.id.toString() || '',
              homeworkTitle: homework.value.homework?.title || '',
              homeworkStatusTargetType: homework.value.status?.targetType || '',
              homeworkStatusTargetId: homework.value.status?.targetId.toString() || ''
            }
          }
        })
      }
    })

    watch(dayStatus, (nDayStatus) => {
      if (nDayStatus && course.value?.gamificationEnabled && !stream.value?.courseFullAccess && nDayStatus?.status !== DAY_STATUS.ENDED) {
        Swal.fire({
          title: String(i18n.global.t('attention')),
          text: String(i18n.global.t('homeworkNotAvailable')),
          confirmButtonText: String(i18n.global.t('good')),
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(() => {
          router.back()
        })
      }
    }, {
      immediate: true
    })

    return {
      isOpenMenu,
      chatTitle,
      loaded,
      stream,
      streamId,
      homework,
      homeworkId,
      canSendHomework,
      menu,
      title,
      status,
      isFree,

      isShowSurveyFormBtn,
      surveyFormUrl,
      isShowFormHomework,
      isShowFormHomeworkAndStatus,
      course
    }
  }
})

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "lCommon lHomework" }
const _hoisted_2 = { class: "lCommon_shadow lCommon_chatHeaderFixed" }
const _hoisted_3 = { class: "lHomework_after" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  class: "lHomework_writeToExpert"
}
const _hoisted_6 = {
  key: 4,
  class: "lHomework_renew"
}
const _hoisted_7 = { class: "lCommon_row" }
const _hoisted_8 = { class: "lHomework_after" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  key: 1,
  class: "lHomework_writeToExpert"
}
const _hoisted_11 = {
  key: 3,
  class: "lHomework_renew"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_as_button_type_form = _resolveComponent("as-button-type-form")!
  const _component_as_chat = _resolveComponent("as-chat")!
  const _component_as_preloader = _resolveComponent("as-preloader")!
  const _component_as_renew_consultations = _resolveComponent("as-renew-consultations")!
  const _component_as_form_homework = _resolveComponent("as-form-homework")!
  const _component_as_homework_status = _resolveComponent("as-homework-status")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_as_renew_course = _resolveComponent("as-renew-course")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_title = _resolveComponent("as-title")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, {
      class: "lCommon -chat",
      target: "mobile-tablet"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_as_header_mobile, {
            title: _ctx.chatTitle,
            back: "",
            "onMenu:toggle": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = $event))
          }, null, 8, ["title"])
        ]),
        (_ctx.loaded)
          ? _withDirectives((_openBlock(), _createBlock(_component_as_chat, {
              key: 0,
              stream: _ctx.stream,
              course: _ctx.course,
              "stream-id": _ctx.streamId,
              class: "lHomework_asChat"
            }, {
              after: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.homework.homework)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: "lHomework_homework",
                        innerHTML: _ctx.homework.homework.text
                      }, null, 8, _hoisted_4))
                    : _createCommentVNode("", true),
                  (_ctx.isShowSurveyFormBtn)
                    ? (_openBlock(), _createBlock(_component_as_button_type_form, {
                        key: 1,
                        class: "-borderless",
                        url: _ctx.surveyFormUrl,
                        result: _ctx.homework.result,
                        homeworkId: _ctx.homeworkId
                      }, null, 8, ["url", "result", "homeworkId"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["stream", "course", "stream-id"])), [
              [_vShow, !_ctx.isOpenMenu]
            ])
          : _withDirectives((_openBlock(), _createBlock(_component_as_preloader, { key: 1 }, null, 512)), [
              [_vShow, !_ctx.isOpenMenu]
            ]),
        (_ctx.loaded && !_ctx.isFree)
          ? _withDirectives((_openBlock(), _createBlock(_component_as_renew_consultations, {
              key: `asRenewConsultations_${_ctx.streamId}`,
              stream: _ctx.stream,
              class: "-chat"
            }, null, 8, ["stream"])), [
              [_vShow, !_ctx.isOpenMenu]
            ])
          : _createCommentVNode("", true),
        (_ctx.isShowFormHomeworkAndStatus)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              (_ctx.isShowFormHomework)
                ? _withDirectives((_openBlock(), _createBlock(_component_as_form_homework, {
                    key: 0,
                    homework: _ctx.homework,
                    "experts-chat-id": _ctx.stream.chatChannelId
                  }, null, 8, ["homework", "experts-chat-id"])), [
                    [_vShow, !_ctx.isOpenMenu]
                  ])
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (_ctx.streamId)
                      ? _withDirectives((_openBlock(), _createBlock(_component_as_homework_status, {
                          key: 0,
                          status: _ctx.status,
                          "stream-id": _ctx.streamId
                        }, null, 8, ["status", "stream-id"])), [
                          [_vShow, !_ctx.isOpenMenu]
                        ])
                      : _createCommentVNode("", true),
                    (_ctx.stream.chatChannelId)
                      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createTextVNode(_toDisplayString(_ctx.$t('IfYouHaveQuestionOrDifficult')) + " ", 1),
                          _createVNode(_component_router_link, {
                            to: { name: 'chat', params: { chatId: _ctx.stream.chatChannelId } }
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('writeToExpert')), 1)
                            ]),
                            _: 1
                          }, 8, ["to"])
                        ], 512)), [
                          [_vShow, !_ctx.isOpenMenu]
                        ])
                      : _createCommentVNode("", true)
                  ], 64))
            ], 64))
          : (_ctx.stream)
            ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_openBlock(), _createBlock(_component_as_renew_course, {
                  class: "-chat",
                  key: `lHomework_renewCourse_${_ctx.stream.id}`,
                  stream: _ctx.stream
                }, null, 8, ["stream"]))
              ], 512)), [
                [_vShow, !_ctx.isOpenMenu]
              ])
            : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, {
      class: "lCommon bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, { title: _ctx.chatTitle }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, { class: "chat" }, _createSlots({
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              mini: true,
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["menu"])
          ]),
          _: 2
        }, [
          (_ctx.loaded)
            ? {
                name: "content",
                fn: _withCtx(() => [
                  _createVNode(_component_as_row, { class: "-inner -chat" }, {
                    content: _withCtx(() => [
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_as_title, {
                          "data-test": "chat-title",
                          class: "-withoutShadow"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.chatTitle), 1)
                          ]),
                          _: 1
                        }),
                        (_ctx.streamId)
                          ? (_openBlock(), _createBlock(_component_as_chat, {
                              key: 0,
                              stream: _ctx.stream,
                              course: _ctx.course,
                              "stream-id": _ctx.streamId,
                              class: "lHomework_asChat"
                            }, {
                              after: _withCtx(() => [
                                _createElementVNode("div", _hoisted_8, [
                                  (_ctx.homework.homework)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "lHomework_homework",
                                        innerHTML: _ctx.homework.homework.text
                                      }, null, 8, _hoisted_9))
                                    : _createCommentVNode("", true),
                                  (_ctx.isShowSurveyFormBtn)
                                    ? (_openBlock(), _createBlock(_component_as_button_type_form, {
                                        key: 1,
                                        class: "-borderless",
                                        url: _ctx.surveyFormUrl,
                                        result: _ctx.homework.result,
                                        homeworkId: _ctx.homeworkId
                                      }, null, 8, ["url", "result", "homeworkId"]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _: 1
                            }, 8, ["stream", "course", "stream-id"]))
                          : _createCommentVNode("", true),
                        (!_ctx.isFree && _ctx.stream)
                          ? (_openBlock(), _createBlock(_component_as_renew_consultations, {
                              key: `asRenewConsultations_${_ctx.streamId}`,
                              stream: _ctx.stream,
                              class: "-chat"
                            }, null, 8, ["stream"]))
                          : _createCommentVNode("", true),
                        (_ctx.isShowFormHomeworkAndStatus)
                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              (_ctx.isShowFormHomework)
                                ? (_openBlock(), _createBlock(_component_as_form_homework, {
                                    key: 0,
                                    "experts-chat-id": _ctx.stream.chatChannelId,
                                    homework: _ctx.homework
                                  }, null, 8, ["experts-chat-id", "homework"]))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    (_ctx.streamId)
                                      ? (_openBlock(), _createBlock(_component_as_homework_status, {
                                          key: 0,
                                          status: _ctx.status,
                                          "stream-id": _ctx.streamId
                                        }, null, 8, ["status", "stream-id"]))
                                      : _createCommentVNode("", true),
                                    (_ctx.stream.chatChannelId)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                          _createTextVNode(_toDisplayString(_ctx.$t('IfYouHaveQuestionOrDifficult')) + " ", 1),
                                          _createVNode(_component_router_link, {
                                            to: { name: 'chat', params: { chatId: _ctx.stream.chatChannelId } }
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.$t('writeToExpert')), 1)
                                            ]),
                                            _: 1
                                          }, 8, ["to"])
                                        ]))
                                      : _createCommentVNode("", true)
                                  ], 64))
                            ], 64))
                          : (_ctx.stream)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                (_openBlock(), _createBlock(_component_as_renew_course, {
                                  class: "-chat",
                                  key: `lHomework_renewCourse_${_ctx.stream.id}`,
                                  stream: _ctx.stream
                                }, null, 8, ["stream"]))
                              ]))
                            : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                key: "0"
              }
            : {
                name: "content",
                fn: _withCtx(() => [
                  _createVNode(_component_as_preloader, { color: "white" })
                ]),
                key: "1"
              }
        ]), 1024),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}
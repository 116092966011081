
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import AsModal from '@web/components/AsModal.vue'
import AsVideoRecord from '@web/components/AsVideoRecord.vue'
import FileApi from '@web/api/modules/File'
import i18n from '@web/plugins/i18n'
import uuid from 'uuid/v4'
import sentry from '@web/common/sentry'
import Swal from 'sweetalert2'

export default defineComponent({
  components: {
    AsModal,
    AsVideoRecord
  },
  props: {
    type: {
      type: String,
      default: 'all'
    }
  },
  emits: ['getRecordData'],
  setup (props, ctx) {
    const status = ref<string>('start')
    const progress = ref<number>(0)
    const modal = ref({
      show: false
    })

    watch(() => modal.value.show, (isShow) => {
      if (!isShow) {
        closeModal()
      }
    })

    const localType = ref<string>('all')
    const data = ref<string>('')

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const hasGetUserMedia = !!navigator.getUserMedia

    const titleText = computed(() => {
      let title = i18n.global.t('Do you want to take a photo or video?')
      if (localType.value === 'image') {
        title = i18n.global.t('Take photo')
      }
      if (localType.value === 'video') {
        title = i18n.global.t('Take video')
      }
      return title
    })

    function openModal () {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => {
            track.stop()
          })

          modal.value.show = true
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: i18n.global.t('Error'),
            text: i18n.global.t('cameraPermissionDenied')
          })
        })
    }
    function closeModal () {
      localType.value = props.type
      data.value = ''
      modal.value.show = false
    }
    function sendRecordData () {
      status.value = 'sending'

      let readyData: File | null = null

      if (localType.value === 'image') {
        const _data: string[] = data.value.split(',')
        let mime = ''
        if (_data.length) {
          const matched = _data[0].match(/:(.*?);/)
          if (matched && matched.length) {
            mime = matched[1]
          }
        }

        const bstr = atob(_data[1])
        let n = bstr.length
        const u8data = new Uint8Array(n)

        while (n--) {
          u8data[n] = bstr.charCodeAt(n)
        }

        readyData = new File([u8data], `${uuid()}.png`, { type: mime })
      }
      if (localType.value === 'video') {
        readyData = new File([data.value], `${uuid()}.mp4`)
      }
      FileApi.upload(readyData, {
        onUploadProgress: progressEvent => {
          progress.value = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
        }
      })
        .then(({ data: newData }) => {
          status.value = 'sent'
          ctx.emit('getRecordData', newData)
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: i18n.global.t('Error'),
            text: i18n.global.t('error.uploadFile')
          })

          sentry.captureException(error)
        })
      closeModal()
    }

    onMounted(() => {
      localType.value = props.type
    })

    return {
      status,
      progress,
      modal,
      localType,
      data,

      titleText,
      hasGetUserMedia,

      openModal,
      closeModal,
      sendRecordData
    }
  }
})

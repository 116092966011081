
import { defineComponent } from 'vue'
import * as typeformEmbed from '@typeform/embed'

export default defineComponent({
  props: {
    url: {
      type: String,
      default: () => ''
    },
    result: {
      required: true
    },
    homeworkId: {
      type: Number
    }
  },
  setup (props) {
    function open () {
      const reference = typeformEmbed.makePopup(
        props.url,
        {
          mode: 'popup',
          hideScrollbars: true
        }
      )

      reference.open()
    }
    return {
      open
    }
  }
})

import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("video", {
    id: _ctx.id,
    playsinline: "",
    autoplay: "",
    class: "video-js vjs-default-skin"
  }, null, 8, _hoisted_1))
}
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64c27a82"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "asFileDnD",
  ref: "asFileDnDEl"
}
const _hoisted_2 = ["id", "accept"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "asFileDnD_start" }
const _hoisted_5 = {
  key: 0,
  class: "asFileDnD_icon"
}
const _hoisted_6 = {
  key: 1,
  class: "asFileDnD_text"
}
const _hoisted_7 = {
  key: 2,
  class: "asFileDnD_btnGroup"
}
const _hoisted_8 = { class: "asFileDnD_button" }
const _hoisted_9 = {
  key: 1,
  class: "asFieldDnD_container"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "asFileDnD_btnGroup" }
const _hoisted_12 = {
  key: 2,
  class: "asFileDnD_container -sending"
}
const _hoisted_13 = { class: "asFileDnD_text" }
const _hoisted_14 = { class: "asFileDnD_progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: "asFileDnD_input",
      id: _ctx.fileId,
      type: "file",
      accept: _ctx.accept,
      name: "file",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fileSelect && _ctx.fileSelect(...args))),
      ref: "fileEl",
      "data-cy": "asFileDnD_input"
    }, null, 40, _hoisted_2),
    (_ctx.status === 'start')
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "asFileDnD_container",
          for: _ctx.fileId,
          onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop","prevent"])),
          onDragleave: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop","prevent"])),
          onDrop: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.fileSelect && _ctx.fileSelect(...args)), ["stop","prevent"]))
        }, [
          _renderSlot(_ctx.$slots, "start", {}, () => [
            _createElementVNode("span", _hoisted_4, [
              (_ctx.showIcon)
                ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                : _createCommentVNode("", true),
              (_ctx.showLabel)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('selectFileOrDragAndDrop')), 1))
                : _createCommentVNode("", true),
              (_ctx.showButton)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('selectFile')), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ], true)
        ], 40, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.status === 'select')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_ctx.showPreview && _ctx.preview)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "asFileDnD_preview",
                src: _ctx.preview,
                alt: ""
              }, null, 8, _hoisted_10))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("span", {
              class: "asFileDnD_button",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.fileUpload && _ctx.fileUpload(...args)))
            }, _toDisplayString(_ctx.$t('sendFile')), 1),
            _createElementVNode("span", {
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args))),
              class: "asFileDnD_reset"
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.status === 'sending')
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('uploadFile')) + " (" + _toDisplayString(_ctx.progress) + "%)", 1),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", {
              style: _normalizeStyle({ width: `${_ctx.progress}%` }),
              class: "asFileDnD_fill"
            }, null, 4)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 512))
}

import { defineComponent, ref, onMounted } from 'vue'

import uuid from 'uuid/v4'
import FileApi from '@web/api/modules/File'
import i18n from '@web/plugins/i18n'
import sentry from '@web/common/sentry'
import Swal from 'sweetalert2'
import { getFileUploadErrorMessage } from '@web/common/Utils'
import Logger from '@web/common/Logger'

export default defineComponent({
  props: {
    accept: {
      type: String,
      default: () => 'image/*'
    },
    showIcon: {
      type: Boolean,
      default: () => true
    },
    showLabel: {
      type: Boolean,
      default: () => true
    },
    showButton: {
      type: Boolean,
      default: () => true
    },
    showPreview: {
      type: Boolean,
      default: () => true
    },
    uploadErrorMessage: {
      type: String,
      default: () => 'error.selfie'
    },
    forceUpload: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['change', 'load', 'loadingFile'],
  setup (props, ctx) {
    const fileId = ref(uuid())
    const preview = ref<string | ArrayBuffer | null>(null)
    const status = ref('start')
    const file = ref<File | null>(null)
    const progress = ref(0)
    const fileEl = ref<HTMLInputElement | null>(null)
    const asFileDnDEl = ref<HTMLElement | null>(null)

    function loadPreview (file) {
      if (!file.type.includes('image')) return
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        preview.value = reader.result
      }, false)

      reader.readAsDataURL(file)
    }

    function reset () {
      preview.value = null
      status.value = 'start'
      if (fileEl.value) {
        fileEl.value.value = ''
      }
    }

    function fileUpload () {
      status.value = 'sending'
      ctx.emit('loadingFile', 'sending')
      FileApi.upload(file.value, {
        onUploadProgress: progressEvent => {
          // не показываю 100%, т.к. сервер ещё какое-то время обрабатывает файл
          progress.value = Math.floor((progressEvent.loaded * 95) / progressEvent.total)
        }
      })
        .then(({ data }) => {
          ctx.emit('load', data)
        })
        .catch(error => {
          sentry.captureException(error)
          ctx.emit('loadingFile', 'fail')
          const alert = (message?: string) => {
            const text = `${i18n.global.t(props.uploadErrorMessage)}. ${message || ''}`
            Swal.fire({
              icon: 'error',
              title: i18n.global.t('Error'),
              text
            })
          }
          getFileUploadErrorMessage(error)
            .then(alert)
            .catch(() => alert())
          Logger.error(error)
        })
        .finally(() => {
          reset()
        })
    }

    function fileSelect (e) {
      const files = e.target.files || e.dataTransfer.files
      const _file = files[0]
      if (!_file) return

      file.value = _file

      ctx.emit('change', _file)
      if (props.forceUpload) {
        fileUpload()
      } else {
        status.value = 'select'
      }
      loadPreview(_file)
    }

    onMounted(() => {
      if (asFileDnDEl.value) {
        asFileDnDEl.value.addEventListener('reset', reset)
      }
    })

    return {
      fileId,
      status,
      preview,
      progress,

      fileUpload,
      fileSelect,
      reset,
      fileEl
    }
  }
})

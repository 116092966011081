
import { defineComponent, computed } from 'vue'

import HomeworkStatus from '@web/consts/HomeworkStatus'

import i18n from '@web/plugins/i18n'
import { useStore } from '@web/store'

export default defineComponent({
  props: {
    status: {
      type: String,
      required: true
    },
    streamId: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const store = useStore()

    const stream = computed(() => store.getters['stream/getStreamById'](props.streamId))
    const courseId = computed(() => stream.value?.courseId)

    const calendarLink = computed(() => {
      return {
        name: 'calendar',
        params: {
          courseId: courseId.value,
          streamId: props.streamId
        }
      }
    })
    const showCalendarLink = computed(() => props.status === HomeworkStatus.UPLOADED || props.status === HomeworkStatus.DONE)
    const calendarLinkText = computed(() => {
      if (props.status === HomeworkStatus.UPLOADED) {
        return `. ${i18n.global.t('expertRespond24')}`
      }
      if (props.status === HomeworkStatus.DONE) {
        return ','
      }

      return ''
    })

    return {
      calendarLink,
      calendarLinkText,
      showCalendarLink,
      courseId
    }
  }
})
